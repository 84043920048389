import React, { useState, useEffect, useRef } from "react"
import ReactPlayer from "react-player"

/* Import Local Styles */
import "./mux-video.css"

const MuxVideo = ({ playbackId, thumbTime = 0, autoplay = false }) => {
  const [isReady, setIsReady] = useState(false)
  const videoRef = useRef()

  useEffect(() => {
    if (videoRef && videoRef.current && isReady && autoplay) {
      videoRef.current.getInternalPlayer().setAttribute("data-keepplaying", "")
    }
  }, [videoRef, isReady, autoplay])

  if (autoplay) {
    return (
      <div className="mux-video player-wrapper">
        <ReactPlayer
          className="react-player"
          width="100%"
          height="100%"
          onReady={() => {
            setIsReady(true)
          }}
          ref={videoRef}
          loop={true}
          volume={0}
          muted={true}
          playing={true}
          playsinline={true}
          pip={false}
          url={`https://stream.mux.com/${playbackId}.m3u8`}
          // light={`https://image.mux.com/${playbackId}/thumbnail.jpg?time=${thumbTime}`}
        />
      </div>
    )
  } else {
    return (
      <div className="mux-video player-wrapper">
        <ReactPlayer
          className="react-player"
          controls
          width="100%"
          height="100%"
          playsinline={true}
          onReady={() => {
            setIsReady(true)
          }}
          ref={videoRef}
          url={`https://stream.mux.com/${playbackId}.m3u8`}
          light={`https://image.mux.com/${playbackId}/thumbnail.jpg?time=${thumbTime}`}
        />
      </div>
    )
  }
}

export default MuxVideo
